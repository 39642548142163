<template lang="pug">
  route-transition
    router-view
</template>

<script>
import RouteTransition from "@/components/RouteTransition.vue";

export default {
  components: {
    RouteTransition,
  },
};
</script>
